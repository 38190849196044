import React from "react";
import photo from "./photo_2023-11-27_01-26-37.jpg"
import "./Banner.css"

export default function Banner() {
    return (
        <section id="banner">
            <div class="content">
                <header>
                    <h1>Hi, I’m Aaron Zamanzadeh</h1>
                    <p>A Full-Stack software engineer based in Los Angeles</p>
                </header>
                <p>
                     I grew up in a family of software engineers and always aspired to being one myself.
                     I have a background in healthcare and retail.
                     I enjoy challenging video games, virtual reality, and building sand castles at the beach.
                     I strongly hope to work on projects using AI and my newly aquired skills and knowledge.
                </p>
                {/* <ul class="actions">
                    <li><a href="#" class="button big">Learn More</a></li>
                </ul> */}
            </div>
            <span class="image object">
                <img src={photo} alt="" id="self"/>
            </span>
		</section>
    )
}
