import React from "react";

import "./Section1.css"

export default function Section1() {
    return (
        <section>
            <header class="major">
                <h2 id="skills">My Skills and Knowledge</h2>
            </header>
            <div class="features">
                <article>
                    <span class="icon brands fa-js"></span>
                    <div class="content">
                        <h3>Javascript</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon brands fa-python"></span>
                    <div class="content">
                        <h3>Python</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon brands fa-react"></span>
                    <div class="content">
                        <h3>ReactJS</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon solid fa-flask"></span>
                    <div class="content">
                        <h3>Flask</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon solid fa-signal"></span>
                    <div class="content">
                        <h3>Redux</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon solid fa-signal"></span>
                    <div class="content">
                        <h3>SqlAlchemy</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon solid fa-signal"></span>
                    <div class="content">
                        <h3>HTML&CSS</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
                <article>
                    <span class="icon solid fa-signal"></span>
                    <div class="content">
                        <h3>Alembic</h3>
                        {/* <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p> */}
                    </div>
                </article>
            </div>
        </section>
    )
}
