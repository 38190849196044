import React from "react";

import './App.css';
import "./assets/css/main.css"
import Header from "./components/Header"
import Banner from "./components/Banner"
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Sidebar from "./components/Sidebar";
function App() {
  return (
    <div className="App">
      <div id="wrapper">
        
        <div id="main">
          <div className="inner">
            <Header />
            <Banner />
            <Section2 />
            <Section1 />
          </div>

        </div>
        <Sidebar/>

      </div>
    </div>
  );
}

export default App;
