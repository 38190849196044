export default function () {
        return {
            capstone: {
                title:"Westwood Beit Kenneset Community Forum",
                description:"The Purpose of this forum is to connect my community and to allow users to create discussions, plan events, and raise funds.",
                tech:{
                    ReactJS:"Utilized React to create frontend interactivity and layout in addition to React Auth Routes to restrict access to user specific data and features to prevent unauthorized users from accessing private information, or changing posts that are not theirs to change.",
                    Flask:"Utilized Flask to provide backend framework and utilized FlaskForms to template data reciept from the frontend.",
                    SqlAlchemy:"Utilized SqlAlchemy to design, model, and write seeders for database in the backend.",
                    Alembic:"Utilized Alembic to migrate and deploy seeders to database",
                    Boto3:"Utilized Boto3 and Amazon Web Service Buckets to allow users to add images to posts in order to better assist their ability to communicate and organize with additional media",
                    Werkzeug:"Utilized Werkzeug Hashing Function to create custom user authentication and to prevent storage of plaintext passwords."
                },
                features:{
                    feeds:"Logged In Users can create, read, and delete feeds.",
                    posts:"Logged In Users can create, read, edit and delete posts.",
                    postImages:"Logged In Users can create, view edit and delete postImages.",
                    postTags:"Logged In Users can create view and delete postTags such as 'likes' or 'attendance' for their posts.",
                    tags:"Logged In Users can create, read, and delete tags when they click 'like' or 'attend' on a post.",
                    replies:"Logged In Users can create, read, edit and delete replies.",
                    donations:"Logged In Users can create and read donations.",
                }
            },
            robinhood: {} 
        }
    
}