import React, { useEffect, useState } from "react";

export default function Sidebar () {
    const thing = ["inactive", "" ]
    const [toggle, setToggle] = useState(false);
    const [value, setValue] = useState(thing[0])

    useEffect(() => {
      setValue(toggle ? thing[1] : thing[0]);
    },[toggle])

    return (
        <div id="sidebar" className={value}>
          <div className="inner">

            {/* <section id="search" class="alt">
              <form method="post" action="#">
                <input type="text" name="query" id="query" placeholder="Search" />
              </form>
            </section> */}

            <nav id="menu">
              <header class="major">
                <h2>Menu</h2>
              </header>
              <ul>
                <li><a href="index.html">Homepage</a></li>
                <li><a href="#projects">My Projects</a></li>
                <li><a href="#skills">My Skills and Knowledge</a></li>
                {/* <li>
                  <span class="opener">Submenu</span>
                  <ul>
                    <li><a href="#">Lorem Dolor</a></li>
                    <li><a href="#">Ipsum Adipiscing</a></li>
                    <li><a href="#">Tempus Magna</a></li>
                    <li><a href="#">Feugiat Veroeros</a></li>
                  </ul>
                </li>
                <li><a href="#">Etiam Dolore</a></li>
                <li><a href="#">Adipiscing</a></li>
                <li>
                  <span class="opener">Another Submenu</span>
                  <ul>
                    <li><a href="#">Lorem Dolor</a></li>
                    <li><a href="#">Ipsum Adipiscing</a></li>
                    <li><a href="#">Tempus Magna</a></li>
                    <li><a href="#">Feugiat Veroeros</a></li>
                  </ul>
                </li>
                <li><a href="#">Maximus Erat</a></li>
                <li><a href="#">Sapien Mauris</a></li>
                <li><a href="#">Amet Lacinia</a></li> */}
              </ul>
            </nav>

            {/* <section>
              <header class="major">
                <h2>Ante interdum</h2>
              </header>
              <div class="mini-posts">
                <article>
                  <a href="#" class="image"><img src="images/pic07.jpg" alt="" /></a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
                <article>
                  <a href="#" class="image"><img src="images/pic08.jpg" alt="" /></a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
                <article>
                  <a href="#" class="image"><img src="images/pic09.jpg" alt="" /></a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
              </div>
              <ul class="actions">
                <li><a href="#" class="button">More</a></li>
              </ul>
            </section> */}

            <section>
              <header class="major">
                <h2>Get in touch</h2>
              </header>
              <ul class="contact">
                <li class="icon solid fa-envelope"><a href="#">zaman.aaron2@gmail.com</a></li>
                <li class="icon solid fa-phone">(310) 733-6035</li>
                <li class="icon solid fa-home">1924 Pelham Ave. <br />
                Los Angeles, CA, 90024<br />
                #101</li>
              </ul>
            </section>

            <footer id="footer">
						  <p class="copyright">&copy; Untitled. All rights reserved.  Design: <a href="https://html5up.net">HTML5 UP</a>.</p>
						</footer>

          </div>
          <a class="toggle" onClick={() => setToggle(!toggle)}></a>

        </div>
    )
}
