import React from 'react';

export default function Header() {
    return (
        <header id="header">
            <a href="index.html" class="logo"><strong>My Portfolio</strong> by Aaron Zamanzadeh</a>
            <ul class="icons">
                <li><a href="https://github.com/Adzamanz" class="icon brands fa-git"><span class="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/in/aaron-zamanzadeh-a10a3275/" class="icon brands fa-linkedin"><span class="label">Facebook</span></a></li>
                {/* <li><a href="#" class="icon brands fa-snapchat-ghost"><span class="label">Snapchat</span></a></li>
                <li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
                <li><a href="#" class="icon brands fa-medium-m"><span class="label">Medium</span></a></li> */}
            </ul>
        </header>
    )
}