import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from "./store";

import { Provider } from "react-redux";


const store = configureStore();

if (process.env.NODE_ENV !== "production") {
	window.store = store;
}

function Root() {
  
  return(
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <script src="./assets/js/jquery.min.js"></script>
        <script src="./assets/js/browser.min.js"></script>
        <script src="./assets/js/breakpoints.min.js"></script>
        <script src="./assets/js/util.js"></script>
        <script src="./assets/js/main.js"></script>

      </BrowserRouter>
     </Provider>
    );
}
ReactDOM.render(
  <React.StrictMode>    
    <Root />    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
