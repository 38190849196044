import React from "react";
import Robinhood from "./robinhood.png"
import Wcf from "./WCF.png"
export default function Section2 () {
    return (
        <section>
									<header class="major">
										<h2 id="projects">My Projects</h2>
									</header>
									<div class="posts">
										<article>
											<a href="#" class="image"><img src={Robinhood} alt="" /></a>
											<h3>Robinhood clone</h3>
											<p>A Robinhood clone that enables users to track, take notes on, “buy”, and “sell” stocks.</p>

                                            <ul>
                                                <li>
                                                    Implemented custom user authentication with Werkzeug Hashing Function to prevent storage of plaintext passwords, and ensure that in the event of a security breach, no sensitive user data is disseminated.
                                                </li>
                                                <li>
                                                    Constructed dynamic display using lightweight charts to provide stock historical data for easy user consumption.
                                                </li>
                                                <li>
                                                Collaborated with three other developers, using git branches, pair programming, and daily standups to discuss progress, obstacles, and goals in order to meet the 14-day project deadline.
                                                </li>
                                            </ul>
                                            <ul class="actions">
												<li><a href="https://robinhood-appacademy.onrender.com" class="button">Visit</a></li>
											</ul>
										</article>
										<article>
											<a href="#" class="image"><img src={Wcf} alt="" /></a>
											<h3>Westwood community forum</h3>
											<p>This Forum empowers users to communicate and coordinate events and to support the synagogue via donations.</p>
                                            <ul>
                                                <li>
													Made use of Amazon Web Service Buckets to allow users to add images to post.
                                                </li>
												<li>
													Employed reactive CSS to emphasize user interaction.
												</li>
                                                <li>
													Used a combination of cookies, flask, and Redux to restrict access to user-specific data and features to prevent unauthorized users from accessing private information, or changing posts that are not theirs to change.
												</li>
                                            </ul>
                                            <ul class="actions">
												<li><a href="https://synagogue-community.onrender.com" class="button">Visit</a></li>
											</ul>
										</article>
										{/* <article>
											<a href="#" class="image"><img src="images/pic03.jpg" alt="" /></a>
											<h3>Tempus ullamcorper</h3>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
											<ul class="actions">
												<li><a href="#" class="button">More</a></li>
											</ul>
										</article>
										<article>
											<a href="#" class="image"><img src="images/pic04.jpg" alt="" /></a>
											<h3>Sed etiam facilis</h3>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
											<ul class="actions">
												<li><a href="#" class="button">More</a></li>
											</ul>
										</article>
										<article>
											<a href="#" class="image"><img src="images/pic05.jpg" alt="" /></a>
											<h3>Feugiat lorem aenean</h3>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
											<ul class="actions">
												<li><a href="#" class="button">More</a></li>
											</ul>
										</article>
										<article>
											<a href="#" class="image"><img src="images/pic06.jpg" alt="" /></a>
											<h3>Amet varius aliquam</h3>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
											<ul class="actions">
												<li><a href="#" class="button">More</a></li>
											</ul>
										</article> */}
									</div>
								</section>
    )
}
